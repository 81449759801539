<template>
    <div class="container">
        <div class="columns">
            <div class="column is-1">
                <field-select label-text="Fangstår" v-model="sampleYear" v-bind:options="sampleYears" />
            </div>
            <div class="column is-8">
                <FylkeVassdrag :model="fylkeVassdrag"></FylkeVassdrag>
            </div>

            <div class="column is-2">
                <field-select label-text="Type fiske" v-model="fishingTypeId" v-bind:options="fishingTypes" />
            </div>
           
            <div class="column is-1">
                <label class="label">Eksporter</label>
                <div class="dropdown" :class="{'is-active': dropdownExpanded}" @click="dropdownExpanded = !dropdownExpanded">
                    <div class="dropdown-trigger">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                            <span class="icon is-small">
                                <i class="fas fa-download" aria-hidden="true"></i>
                            </span>
                            <span class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a @click="downloadReport()" class="dropdown-item">
                                Betalingsoversikt
                            </a>                           
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
            <thead class="has-background-info-light">
                <tr>
                    <th>Fangstår</th>
                    <th>Fylke</th>
                    <th>Vassdrag</th>
                    <th>Type</th>
                    <th>Ant prøver</th>
                    <th>Innsender</th>
                    <th>Kontonr</th>
                    <th><abbr title="Antall prøver utbetalt">Ant utbetalt</abbr></th>
                    <th>Beløp</th>
                    <th>Navn</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(reg, index) in filteredList" :key="index">
                    <td>{{ reg.sampleYear }}</td>
                    <td> {{ reg.fylkeNavn }}</td>
                    <td>{{ reg.vassdragNavn }}</td>
                    <td>{{ getFishingType(reg.fishingTypeId) }}</td>
                    <td>{{ reg.totalSamples }}</td>
                    <td>{{ reg.innsender }}</td>
                    <td>{{ reg.kontonummer }}</td>
                    <td> {{ reg.antProverUtbetalt }}</td>
                    <td>{{ reg.belop }}</td>
                    <td>{{ reg.navn }}</td>
                    <td><button :disabled="true" @click="showFormModal(index) "
                        class="button is-small is-outlined is-info is-pulled-right ml-2">
                        <span class="icon is-small">
                            <i class="fas fa-pen"></i>
                        </span>
                    </button></td>

                </tr>
            </tbody>
        </table>
        <form-modal ref="formModal" title="Registrer betaling">
            <component :is="betalingForm" :model="betaling" @formSubmitted="saveBetalingVassdrag" ></component>
        </form-modal>

    </div>
</template>
  
<script>

import api from "@/api/basicApi.js";
import FylkeVassdrag from '../../grouped/FylkeVassdrag.vue';
import fishingTypeEnum from "@/enums/FishingTypeEnum";
import { PROVEBASE_CONFIG } from "@/../config.js";
import FormModal from '../../modals/FormModal.vue';
import RegistrerBetaling from './RegistrerBetaling.vue'

export default {

    data: function () {
        return {
            registreringer: [],
            fylkeVassdrag: {
                fylkeId: '',
                vassdragId: null
            },
            fishingTypes: fishingTypeEnum,
            
            selectedSpecies: [],
            fishingTypeId: null,
            sampleYears: [],
            sampleYear: null,
            dropdownExpanded: false,
            betalingForm: RegistrerBetaling,
            currentIndex: null,
            betaling: {}
        }
    },
    components: {
        FylkeVassdrag, "form-modal": FormModal, RegistrerBetaling
    },
    mounted() {
        let self = this;
        self.getVassdragOversiktBetaling()
        api.fetchData({ url: "MottakFiskeskjellAktivitet/GetSampleYears" })
            .then(res => {
                self.sampleYears = res.data.sampleYears
                self.sampleYear = res.data.defaultYear
            });

    },
    computed: {
        filteredList() {
        if (this.fylkeVassdrag.fylkeId != '' || this.fishingTypeId > 0 || this.fylkeVassdrag.vassdragId != null || this.selectedSpecies.length > 0) {
            let filterFunction = () => true;

            if (this.fylkeVassdrag.fylkeId != '') {
                const prevFilter = filterFunction;
                filterFunction = item => prevFilter(item) && item.fylkeId === this.fylkeVassdrag.fylkeId;
            }
            if (this.fishingTypeId > 0) {
                const prevFilter = filterFunction;
                filterFunction = item => prevFilter(item) && item.fishingTypeId === this.fishingTypeId;
            }
            if (this.fylkeVassdrag.vassdragId != null) {
                const prevFilter = filterFunction;
                filterFunction = item => prevFilter(item) && item.vassdragId === this.fylkeVassdrag.vassdragId;
            }
            return this.registreringer.filter(filterFunction);
        } else {
            return this.registreringer;
        }
        }
    },

    methods: {
        showFormModal(index) {
            this.currentIndex = index 
            var rowInfo = this.registreringer[this.currentIndex];
            var id = rowInfo.betalingId
            let self = this

            if (id) {
                api.fetchData({ url: "MottakFiskeskjellOversikt/GetBetaling/" +  id }).then(res => {
                    self.betaling = res.data
                })
            } else {
                self.betaling = {}
            }
            
            this.$refs.formModal.show();
        },
        saveBetalingVassdrag(formData) {
        
            var rowInfo = this.registreringer[this.currentIndex];
            if (!rowInfo.betalingId) {
                formData.fylkeId = rowInfo.fylkeId
                formData.fishingTypeId = rowInfo.fishingTypeId
                formData.sampleYear = rowInfo.sampleYear
                formData.vassdragId = rowInfo.vassdragId
                formData.funnsted = rowInfo.vassdragNavn    
            }
            
            api.saveData({url: "MottakFiskeskjellOversikt/SaveBetalingsinformasjon", data: formData}).then(res => {
                rowInfo.betalingId = res.data,
                rowInfo.navn = formData.navn,
                rowInfo.antProverUtbetalt = formData.antProverUtbetalt,
                rowInfo.belop = formData.belop
            })

            this.$refs.formModal.close();
            
        },

        getFishingType(id) {
            return this.fishingTypes[id - 1].name;
        },
        getVassdragOversiktBetaling() {
            let self = this
            if (self.sampleYear != null) {
                api.fetchData({ url: "MottakFiskeskjellOversikt/GetVassdragOversiktAggregertBetaling/" + self.sampleYear }).then(res => {
                    self.registreringer = res.data
                });
            }
        },
        downloadReport() {
            api.fetchData({ url: "MottakFiskeskjellOversikt/GetEncryptedToken" }).then(res => {
                let tokenFromServer = res.data;
                let queryString = "encryptedToken=" + tokenFromServer;
                queryString += "&sampleYear=" + this.sampleYear + "&countyId="+ this.fylkeVassdrag.fylkeId 
                + "&vassdragId="+ this.fylkeVassdrag.vassdragId + "&fishingTypeId="+ this.fishingTypeId

                if (this.sampleYear != null) {
                    window.location.href =  `${PROVEBASE_CONFIG.API_URL}/` + "MottakFiskeskjellOversikt/BetalingReport?" + queryString;
                }
             })
        }
    },
    watch: {
        'sampleYear'(newVal) {
            if (newVal != null) {
                this.getVassdragOversiktBetaling()
            }

        }
    }
};
</script>
  